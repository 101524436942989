/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal.js";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const editor_config = $root.editor_config = (() => {

    /**
     * Properties of an editor_config.
     * @exports Ieditor_config
     * @interface Ieditor_config
     * @property {Idevice_config|null} [device] editor_config device
     */

    /**
     * Constructs a new editor_config.
     * @exports editor_config
     * @classdesc Represents an editor_config.
     * @implements Ieditor_config
     * @constructor
     * @param {Ieditor_config=} [p] Properties to set
     */
    function editor_config(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * editor_config device.
     * @member {Idevice_config|null|undefined} device
     * @memberof editor_config
     * @instance
     */
    editor_config.prototype.device = null;

    /**
     * Encodes the specified editor_config message. Does not implicitly {@link editor_config.verify|verify} messages.
     * @function encode
     * @memberof editor_config
     * @static
     * @param {Ieditor_config} m editor_config message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    editor_config.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.device != null && Object.hasOwnProperty.call(m, "device"))
            $root.device_config.encode(m.device, w.uint32(10).fork()).ldelim();
        return w;
    };

    /**
     * Decodes an editor_config message from the specified reader or buffer.
     * @function decode
     * @memberof editor_config
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {editor_config} editor_config
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    editor_config.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.editor_config();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.device = $root.device_config.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies an editor_config message.
     * @function verify
     * @memberof editor_config
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    editor_config.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.device != null && m.hasOwnProperty("device")) {
            {
                var e = $root.device_config.verify(m.device);
                if (e)
                    return "device." + e;
            }
        }
        return null;
    };

    return editor_config;
})();

export const device_config = $root.device_config = (() => {

    /**
     * Properties of a device_config.
     * @exports Idevice_config
     * @interface Idevice_config
     * @property {Array.<Iblock>|null} [blocks] device_config blocks
     * @property {Array.<Iconnection>|null} [connections] device_config connections
     */

    /**
     * Constructs a new device_config.
     * @exports device_config
     * @classdesc Represents a device_config.
     * @implements Idevice_config
     * @constructor
     * @param {Idevice_config=} [p] Properties to set
     */
    function device_config(p) {
        this.blocks = [];
        this.connections = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * device_config blocks.
     * @member {Array.<Iblock>} blocks
     * @memberof device_config
     * @instance
     */
    device_config.prototype.blocks = $util.emptyArray;

    /**
     * device_config connections.
     * @member {Array.<Iconnection>} connections
     * @memberof device_config
     * @instance
     */
    device_config.prototype.connections = $util.emptyArray;

    /**
     * Encodes the specified device_config message. Does not implicitly {@link device_config.verify|verify} messages.
     * @function encode
     * @memberof device_config
     * @static
     * @param {Idevice_config} m device_config message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    device_config.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.blocks != null && m.blocks.length) {
            for (var i = 0; i < m.blocks.length; ++i)
                $root.block.encode(m.blocks[i], w.uint32(10).fork()).ldelim();
        }
        if (m.connections != null && m.connections.length) {
            for (var i = 0; i < m.connections.length; ++i)
                $root.connection.encode(m.connections[i], w.uint32(18).fork()).ldelim();
        }
        return w;
    };

    /**
     * Decodes a device_config message from the specified reader or buffer.
     * @function decode
     * @memberof device_config
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {device_config} device_config
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    device_config.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.device_config();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    if (!(m.blocks && m.blocks.length))
                        m.blocks = [];
                    m.blocks.push($root.block.decode(r, r.uint32()));
                    break;
                }
            case 2: {
                    if (!(m.connections && m.connections.length))
                        m.connections = [];
                    m.connections.push($root.connection.decode(r, r.uint32()));
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a device_config message.
     * @function verify
     * @memberof device_config
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    device_config.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.blocks != null && m.hasOwnProperty("blocks")) {
            if (!Array.isArray(m.blocks))
                return "blocks: array expected";
            for (var i = 0; i < m.blocks.length; ++i) {
                {
                    var e = $root.block.verify(m.blocks[i]);
                    if (e)
                        return "blocks." + e;
                }
            }
        }
        if (m.connections != null && m.hasOwnProperty("connections")) {
            if (!Array.isArray(m.connections))
                return "connections: array expected";
            for (var i = 0; i < m.connections.length; ++i) {
                {
                    var e = $root.connection.verify(m.connections[i]);
                    if (e)
                        return "connections." + e;
                }
            }
        }
        return null;
    };

    return device_config;
})();

export const block = $root.block = (() => {

    /**
     * Properties of a block.
     * @exports Iblock
     * @interface Iblock
     * @property {number|null} [x] block x
     * @property {number|null} [y] block y
     * @property {Array.<number>|null} [connectors] block connectors
     * @property {generator.Istatic_color|null} [generator_static] block generator_static
     * @property {output.Iaddressable|null} [output_addressable] block output_addressable
     * @property {processing.Irepeater|null} [processing_repeater] block processing_repeater
     */

    /**
     * Constructs a new block.
     * @exports block
     * @classdesc Represents a block.
     * @implements Iblock
     * @constructor
     * @param {Iblock=} [p] Properties to set
     */
    function block(p) {
        this.connectors = [];
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * block x.
     * @member {number} x
     * @memberof block
     * @instance
     */
    block.prototype.x = 0;

    /**
     * block y.
     * @member {number} y
     * @memberof block
     * @instance
     */
    block.prototype.y = 0;

    /**
     * block connectors.
     * @member {Array.<number>} connectors
     * @memberof block
     * @instance
     */
    block.prototype.connectors = $util.emptyArray;

    /**
     * block generator_static.
     * @member {generator.Istatic_color|null|undefined} generator_static
     * @memberof block
     * @instance
     */
    block.prototype.generator_static = null;

    /**
     * block output_addressable.
     * @member {output.Iaddressable|null|undefined} output_addressable
     * @memberof block
     * @instance
     */
    block.prototype.output_addressable = null;

    /**
     * block processing_repeater.
     * @member {processing.Irepeater|null|undefined} processing_repeater
     * @memberof block
     * @instance
     */
    block.prototype.processing_repeater = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * block implementation.
     * @member {"generator_static"|"output_addressable"|"processing_repeater"|undefined} implementation
     * @memberof block
     * @instance
     */
    Object.defineProperty(block.prototype, "implementation", {
        get: $util.oneOfGetter($oneOfFields = ["generator_static", "output_addressable", "processing_repeater"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Encodes the specified block message. Does not implicitly {@link block.verify|verify} messages.
     * @function encode
     * @memberof block
     * @static
     * @param {Iblock} m block message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    block.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.x != null && Object.hasOwnProperty.call(m, "x"))
            w.uint32(8).sint32(m.x);
        if (m.y != null && Object.hasOwnProperty.call(m, "y"))
            w.uint32(16).sint32(m.y);
        if (m.connectors != null && m.connectors.length) {
            w.uint32(26).fork();
            for (var i = 0; i < m.connectors.length; ++i)
                w.int32(m.connectors[i]);
            w.ldelim();
        }
        if (m.generator_static != null && Object.hasOwnProperty.call(m, "generator_static"))
            $root.generator.static_color.encode(m.generator_static, w.uint32(130).fork()).ldelim();
        if (m.output_addressable != null && Object.hasOwnProperty.call(m, "output_addressable"))
            $root.output.addressable.encode(m.output_addressable, w.uint32(138).fork()).ldelim();
        if (m.processing_repeater != null && Object.hasOwnProperty.call(m, "processing_repeater"))
            $root.processing.repeater.encode(m.processing_repeater, w.uint32(146).fork()).ldelim();
        return w;
    };

    /**
     * Decodes a block message from the specified reader or buffer.
     * @function decode
     * @memberof block
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {block} block
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    block.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.block();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.x = r.sint32();
                    break;
                }
            case 2: {
                    m.y = r.sint32();
                    break;
                }
            case 3: {
                    if (!(m.connectors && m.connectors.length))
                        m.connectors = [];
                    if ((t & 7) === 2) {
                        var c2 = r.uint32() + r.pos;
                        while (r.pos < c2)
                            m.connectors.push(r.int32());
                    } else
                        m.connectors.push(r.int32());
                    break;
                }
            case 16: {
                    m.generator_static = $root.generator.static_color.decode(r, r.uint32());
                    break;
                }
            case 17: {
                    m.output_addressable = $root.output.addressable.decode(r, r.uint32());
                    break;
                }
            case 18: {
                    m.processing_repeater = $root.processing.repeater.decode(r, r.uint32());
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a block message.
     * @function verify
     * @memberof block
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    block.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        var p = {};
        if (m.x != null && m.hasOwnProperty("x")) {
            if (!$util.isInteger(m.x))
                return "x: integer expected";
        }
        if (m.y != null && m.hasOwnProperty("y")) {
            if (!$util.isInteger(m.y))
                return "y: integer expected";
        }
        if (m.connectors != null && m.hasOwnProperty("connectors")) {
            if (!Array.isArray(m.connectors))
                return "connectors: array expected";
            for (var i = 0; i < m.connectors.length; ++i) {
                if (!$util.isInteger(m.connectors[i]))
                    return "connectors: integer[] expected";
            }
        }
        if (m.generator_static != null && m.hasOwnProperty("generator_static")) {
            p.implementation = 1;
            {
                var e = $root.generator.static_color.verify(m.generator_static);
                if (e)
                    return "generator_static." + e;
            }
        }
        if (m.output_addressable != null && m.hasOwnProperty("output_addressable")) {
            if (p.implementation === 1)
                return "implementation: multiple values";
            p.implementation = 1;
            {
                var e = $root.output.addressable.verify(m.output_addressable);
                if (e)
                    return "output_addressable." + e;
            }
        }
        if (m.processing_repeater != null && m.hasOwnProperty("processing_repeater")) {
            if (p.implementation === 1)
                return "implementation: multiple values";
            p.implementation = 1;
            {
                var e = $root.processing.repeater.verify(m.processing_repeater);
                if (e)
                    return "processing_repeater." + e;
            }
        }
        return null;
    };

    return block;
})();

export const generator = $root.generator = (() => {

    /**
     * Namespace generator.
     * @exports generator
     * @namespace
     */
    const generator = {};

    generator.static_color = (function() {

        /**
         * Properties of a static_color.
         * @memberof generator
         * @interface Istatic_color
         * @property {number|null} [color] static_color color
         */

        /**
         * Constructs a new static_color.
         * @memberof generator
         * @classdesc Represents a static_color.
         * @implements Istatic_color
         * @constructor
         * @param {generator.Istatic_color=} [p] Properties to set
         */
        function static_color(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * static_color color.
         * @member {number} color
         * @memberof generator.static_color
         * @instance
         */
        static_color.prototype.color = 0;

        /**
         * Encodes the specified static_color message. Does not implicitly {@link generator.static_color.verify|verify} messages.
         * @function encode
         * @memberof generator.static_color
         * @static
         * @param {generator.Istatic_color} m static_color message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        static_color.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.color != null && Object.hasOwnProperty.call(m, "color"))
                w.uint32(13).fixed32(m.color);
            return w;
        };

        /**
         * Decodes a static_color message from the specified reader or buffer.
         * @function decode
         * @memberof generator.static_color
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {generator.static_color} static_color
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        static_color.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.generator.static_color();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.color = r.fixed32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Verifies a static_color message.
         * @function verify
         * @memberof generator.static_color
         * @static
         * @param {Object.<string,*>} m Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        static_color.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.color != null && m.hasOwnProperty("color")) {
                if (!$util.isInteger(m.color))
                    return "color: integer expected";
            }
            return null;
        };

        return static_color;
    })();

    return generator;
})();

export const output = $root.output = (() => {

    /**
     * Namespace output.
     * @exports output
     * @namespace
     */
    const output = {};

    output.addressable = (function() {

        /**
         * Properties of an addressable.
         * @memberof output
         * @interface Iaddressable
         * @property {number|null} [pin] addressable pin
         * @property {string|null} [order] addressable order
         * @property {number|null} [flags] addressable flags
         */

        /**
         * Constructs a new addressable.
         * @memberof output
         * @classdesc Represents an addressable.
         * @implements Iaddressable
         * @constructor
         * @param {output.Iaddressable=} [p] Properties to set
         */
        function addressable(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * addressable pin.
         * @member {number} pin
         * @memberof output.addressable
         * @instance
         */
        addressable.prototype.pin = 0;

        /**
         * addressable order.
         * @member {string} order
         * @memberof output.addressable
         * @instance
         */
        addressable.prototype.order = "";

        /**
         * addressable flags.
         * @member {number} flags
         * @memberof output.addressable
         * @instance
         */
        addressable.prototype.flags = 0;

        /**
         * Encodes the specified addressable message. Does not implicitly {@link output.addressable.verify|verify} messages.
         * @function encode
         * @memberof output.addressable
         * @static
         * @param {output.Iaddressable} m addressable message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        addressable.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.pin != null && Object.hasOwnProperty.call(m, "pin"))
                w.uint32(8).int32(m.pin);
            if (m.order != null && Object.hasOwnProperty.call(m, "order"))
                w.uint32(18).string(m.order);
            if (m.flags != null && Object.hasOwnProperty.call(m, "flags"))
                w.uint32(24).int32(m.flags);
            return w;
        };

        /**
         * Decodes an addressable message from the specified reader or buffer.
         * @function decode
         * @memberof output.addressable
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {output.addressable} addressable
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        addressable.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.output.addressable();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.pin = r.int32();
                        break;
                    }
                case 2: {
                        m.order = r.string();
                        break;
                    }
                case 3: {
                        m.flags = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Verifies an addressable message.
         * @function verify
         * @memberof output.addressable
         * @static
         * @param {Object.<string,*>} m Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        addressable.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.pin != null && m.hasOwnProperty("pin")) {
                if (!$util.isInteger(m.pin))
                    return "pin: integer expected";
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                if (!$util.isString(m.order))
                    return "order: string expected";
            }
            if (m.flags != null && m.hasOwnProperty("flags")) {
                if (!$util.isInteger(m.flags))
                    return "flags: integer expected";
            }
            return null;
        };

        /**
         * flag enum.
         * @name output.addressable.flag
         * @enum {number}
         * @property {number} FLAG_NONE=0 FLAG_NONE value
         * @property {number} FLAG_800KHZ=1 FLAG_800KHZ value
         * @property {number} FLAG_ACTIVE_HIGH=2 FLAG_ACTIVE_HIGH value
         */
        addressable.flag = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "FLAG_NONE"] = 0;
            values[valuesById[1] = "FLAG_800KHZ"] = 1;
            values[valuesById[2] = "FLAG_ACTIVE_HIGH"] = 2;
            return values;
        })();

        return addressable;
    })();

    return output;
})();

export const processing = $root.processing = (() => {

    /**
     * Namespace processing.
     * @exports processing
     * @namespace
     */
    const processing = {};

    processing.repeater = (function() {

        /**
         * Properties of a repeater.
         * @memberof processing
         * @interface Irepeater
         * @property {number|null} [factor] repeater factor
         */

        /**
         * Constructs a new repeater.
         * @memberof processing
         * @classdesc Represents a repeater.
         * @implements Irepeater
         * @constructor
         * @param {processing.Irepeater=} [p] Properties to set
         */
        function repeater(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * repeater factor.
         * @member {number} factor
         * @memberof processing.repeater
         * @instance
         */
        repeater.prototype.factor = 0;

        /**
         * Encodes the specified repeater message. Does not implicitly {@link processing.repeater.verify|verify} messages.
         * @function encode
         * @memberof processing.repeater
         * @static
         * @param {processing.Irepeater} m repeater message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        repeater.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.factor != null && Object.hasOwnProperty.call(m, "factor"))
                w.uint32(8).int32(m.factor);
            return w;
        };

        /**
         * Decodes a repeater message from the specified reader or buffer.
         * @function decode
         * @memberof processing.repeater
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {processing.repeater} repeater
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        repeater.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.processing.repeater();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1: {
                        m.factor = r.int32();
                        break;
                    }
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Verifies a repeater message.
         * @function verify
         * @memberof processing.repeater
         * @static
         * @param {Object.<string,*>} m Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        repeater.verify = function verify(m) {
            if (typeof m !== "object" || m === null)
                return "object expected";
            if (m.factor != null && m.hasOwnProperty("factor")) {
                if (!$util.isInteger(m.factor))
                    return "factor: integer expected";
            }
            return null;
        };

        return repeater;
    })();

    return processing;
})();

export const connection = $root.connection = (() => {

    /**
     * Properties of a connection.
     * @exports Iconnection
     * @interface Iconnection
     * @property {number|null} [from] connection from
     * @property {number|null} [to] connection to
     */

    /**
     * Constructs a new connection.
     * @exports connection
     * @classdesc Represents a connection.
     * @implements Iconnection
     * @constructor
     * @param {Iconnection=} [p] Properties to set
     */
    function connection(p) {
        if (p)
            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                if (p[ks[i]] != null)
                    this[ks[i]] = p[ks[i]];
    }

    /**
     * connection from.
     * @member {number} from
     * @memberof connection
     * @instance
     */
    connection.prototype.from = 0;

    /**
     * connection to.
     * @member {number} to
     * @memberof connection
     * @instance
     */
    connection.prototype.to = 0;

    /**
     * Encodes the specified connection message. Does not implicitly {@link connection.verify|verify} messages.
     * @function encode
     * @memberof connection
     * @static
     * @param {Iconnection} m connection message or plain object to encode
     * @param {$protobuf.Writer} [w] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    connection.encode = function encode(m, w) {
        if (!w)
            w = $Writer.create();
        if (m.from != null && Object.hasOwnProperty.call(m, "from"))
            w.uint32(8).int32(m.from);
        if (m.to != null && Object.hasOwnProperty.call(m, "to"))
            w.uint32(16).int32(m.to);
        return w;
    };

    /**
     * Decodes a connection message from the specified reader or buffer.
     * @function decode
     * @memberof connection
     * @static
     * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
     * @param {number} [l] Message length if known beforehand
     * @returns {connection} connection
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    connection.decode = function decode(r, l) {
        if (!(r instanceof $Reader))
            r = $Reader.create(r);
        var c = l === undefined ? r.len : r.pos + l, m = new $root.connection();
        while (r.pos < c) {
            var t = r.uint32();
            switch (t >>> 3) {
            case 1: {
                    m.from = r.int32();
                    break;
                }
            case 2: {
                    m.to = r.int32();
                    break;
                }
            default:
                r.skipType(t & 7);
                break;
            }
        }
        return m;
    };

    /**
     * Verifies a connection message.
     * @function verify
     * @memberof connection
     * @static
     * @param {Object.<string,*>} m Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    connection.verify = function verify(m) {
        if (typeof m !== "object" || m === null)
            return "object expected";
        if (m.from != null && m.hasOwnProperty("from")) {
            if (!$util.isInteger(m.from))
                return "from: integer expected";
        }
        if (m.to != null && m.hasOwnProperty("to")) {
            if (!$util.isInteger(m.to))
                return "to: integer expected";
        }
        return null;
    };

    return connection;
})();

export { $root as default };
